<template>
  <div class="SBei">
    <van-dialog v-model="show" title="设备解绑" show-cancel-button confirmButtonColor="#0070fb" @confirm="jiebang">
      <van-field v-model="password" type="password" label="解绑密码" />
    </van-dialog>
    <div class="sb_top_f"  v-if="deviceSn.startsWith('HQTF')">
      <div class="top_h">
        <div>租借二维码:{{ deviceSn }}</div>
        <!-- <div>通信模组版本:{{ device_version ? device_version.modVer : '' }}</div> -->
      </div>
      <div class="top_h">
        <div>IMEI:{{ device_version ? device_version.deviceName : '' }}</div>
        <div>通信模组版本:{{ device_version ? device_version.modVer : '' }}</div>
      </div>
      <div class="top_h">
        <div>主控固件版本:{{ device_version ? device_version.romVer : '' }}</div>
        <div>从控固件版本:{{ device_version ? device_version.subRomVer : '' }}</div>
      </div>
      <div class="top_h">
        <div class="ccid">CCID:{{ device_version ? device_version.iccid : '' }}</div>
      </div>
      <div class="top_h">
        <div class="sqjg" @click="popAllOutput">全部弹出</div>
        <div class="sqjg" @click="refresh">刷新</div>
      </div>
    </div>
    <div class="sb_top" v-else>
      <div class="top_h">
        <div>柜:{{ obj.deviceName }}</div>
        <div>4G信号:{{ obj.signalStrength4G }}</div>
      </div>
      <div class="top_h">
        <div>机柜温度:{{ obj.temperature }}</div>
        <div>硬件版本:{{ obj.hardware }}</div>
        <div>固件版本:{{ obj.firmware }}</div>
      </div>
      <div class="top_h">
        <div class="ccid">CCID:{{ obj.ccid }}</div>
        <div class="sqjg" @click="restart">重启机柜</div>
      </div>
      <div class="top_h">
        <div class="sqjg" @click="popAllOutput">开输出全部弹出</div>
        <div class="sqjg" @click="popAllShutOutput">关输出全部弹出</div>
        <div class="sqjg" @click="refresh">刷新</div>
      </div>
    </div>

    <div v-if="deviceSn.startsWith('HQTF')">
      <div class="sb_top_fbox" v-for="item in obj" :key="item.layerSn">
        <div class="top_h sb_top_fh">
          <div>层序列编号:{{ item.layerSn }}</div>
          <div>层固件版本:{{ item.mcuVersion }}</div>
        </div>
        <div class="sb_item" v-for="item1 in item.holeSites" :key="item1.slotId">
          <div class="top_h">
            <div>仓位:{{ item1.slotId }}</div>
            <div style="color: gray;">电量:{{ item1.battery }}mAh{{ item1.pointBattery }}%</div>
            <div>SN:{{ item1.powerbankNo }}</div>
          </div>

          <div class="top_h">
            <div class="sqjg" style="padding: 2px 8px;" @click="popOutput(item1.slotId)">
              开输出弹出
            </div>
            <div class="sqjg" style="padding: 2px 8px;" @click="popShutOutput(item1.slotId)">
              关输出弹出
            </div>
            <div style="color: gray;">温度:{{ item1.temp }}</div>
            <div>
              <span :class="{
                kc: item1.sstatus == 0,
                zc: item1.sstatus == 1,
                ws: item1.sstatus == 3,
                gz: item1.sstatus == 4,
              }">{{ getPbStatus(item1) }}</span>
            </div>
          </div>
        </div>

      </div>

    </div>
    <div v-else>
      <div class="sb_top bk" v-for="item in obj.holeSites" :key="item.holeSiteIndex">
        <div class="top_h">
          <div>仓位:{{ item.holeSiteIndex }}</div>
          <div>SN:{{ item.powerBankSn }}</div>
          <div>
            固/硬件版本:{{ item.powerBankFirmware }}/{{ item.powerBankHardware }}
          </div>
        </div>
        <div class="top_h">
          <div>电量:{{ item.electricQuantity }}mAh{{ item.electricRatio }}%</div>
          <div>电芯电压:{{ item.cellVoltage }}V</div>
          <div>温度:{{ item.powerBankTemperature }}</div>
        </div>
        <div class="top_h">
          <div>充电电压:{{ item.voltage }}V</div>
          <div>充电电流:{{ item.electricity }}A</div>
          <div>健康度:{{ item.cellLife }}%</div>
        </div>
        <div class="top_h">
          <div class="sqjg" @click="popOutput(item.holeSiteIndex)">
            开输出弹出
          </div>
          <div class="sqjg" @click="popShutOutput(item.holeSiteIndex)">
            关输出弹出
          </div>
          <div>
            状态:<span :class="{
              kc: item.status == 0,
              zc: item.status == 1,
              ws: item.status == 3,
              gz: item.status == 4,
            }">{{ getPbStatus(item) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showLoading" class="parentBox" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%;">
      <div class="loadBox">
       <div class="loaderContantBox"></div>
      </div>
    </div>

  </div>
</template>
<script>
import { Notify } from "vant";
export default {
  data() {
    return {
      obj: {},
      showLoading:false,
      device_version: null,
      deviceSn: localStorage.getItem("deviceSn"),
      // deviceSn: "HQT20210301",
      timer: "",
      show: false,
      password: "",
    };
  },
  created() {
    this.getlist();
    this.timer = setInterval(() => {
      this.getlist();
    }, 20000);
  },
  destroyed() {
    console.log("likaile 0-------------------");
    clearInterval(this.timer);
  },
  methods: {
    unbind() {
      this.show = true;
    },
    jiebang() {
      if (!this.password) {
        alert("请输入密码");
      } else {
        console.log(this.password);
        this.$http
          .unbindDevice({
            password: this.password,
            deviceName: this.obj.deviceName,
          })
          .then((res) => {
            alert(res.msg);
            this.password = "";
          });
      }
    },
    getlist() {
      this.$http.deviceInfo({ deviceSn: this.deviceSn }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          let resdata = res.data;
          if (this.deviceSn.startsWith('HQTF')) {
            var arr1 = []
            var arr3 = []
            for (var key in resdata) {
              var m0 = resdata[key]
              if (m0.holeSites && m0.holeSites.length > 0){
                arr1.push(m0)
              }else{
                arr3.push(m0)
              }
            }
            for (var i = 0; i < arr1.length - 1; i++) {
              for (var j = 0; j < arr1.length - i - 1; j++) {
                // 1.对每一个值和它的下一个值进行比较
                var m1 = arr1[j].holeSites[0].slotId
                var m2 = arr1[j+1].holeSites[0].slotId
                if (m1 > m2) {
                  // 如果第一个值更多，则将其赋予自定义计数值 count
                  var count = arr1[j];
                  // 反复交换
                  arr1[j] = arr1[j + 1];
                  arr1[j + 1] = count;
                };
              };
            };
            if (arr3.length > 0){
              arr1 = arr1.concat(arr3)
            }
            this.obj = arr1;
          } else {
            this.obj = res.data
          }

          if (res.device_version) {
            this.device_version = res.device_version
          }
        } else {
          Notify({ type: "primary", message: res.msg });
        }
      });
    },
    restart() {
      this.$http.restart({ deviceName: this.obj.deviceName }).then((res) => {
        console.log(res);
        Notify({ type: "primary", message: res.msg });
      });
    },
    popAllOutput() {
      var params = {
        deviceSn: this.deviceSn
      }
      if (this.device_version) {
        params.deviceName = this.device_version.deviceName
      } else {
        params.deviceName = this.obj.deviceName
      }
      this.$http
        .popAllOutput(params)
        .then((res) => {
          console.log(res);
          Notify({ type: "primary", message: res.msg });
        });
    },
    popAllShutOutput() {
      var params = {
        deviceSn: this.deviceSn
      }
      if (this.device_version) {
        params.deviceName = this.device_version.deviceName
      } else {
        params.deviceName = this.obj.deviceName
      }
      this.$http
        .popAllShutOutput(params)
        .then((res) => {
          console.log(res);
          Notify({ type: "primary", message: res.msg });
        });
    },
    refresh() {
      this.showLoading = true
      setTimeout(res=>{
        this.showLoading = false
      },3000)
      this.$http.deviceInfo({ deviceSn: this.deviceSn }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          let resdata = res.data;
          if (this.deviceSn.startsWith('HQTF')) {
            var arr1 = []
            var arr3 = []
            for (var key in resdata) {
              var m0 = resdata[key]
              if (m0.holeSites && m0.holeSites.length > 0){
                arr1.push(m0)
              }else{
                arr3.push(m0)
              }
            }
            for (var i = 0; i < arr1.length - 1; i++) {
              for (var j = 0; j < arr1.length - i - 1; j++) {
                // 1.对每一个值和它的下一个值进行比较
                var m1 = arr1[j].holeSites[0].slotId
                var m2 = arr1[j+1].holeSites[0].slotId
                if (m1 > m2) {
                  // 如果第一个值更多，则将其赋予自定义计数值 count
                  var count = arr1[j];
                  // 反复交换
                  arr1[j] = arr1[j + 1];
                  arr1[j + 1] = count;
                };
              };
            };
            if (arr3.length > 0){
              arr1 = arr1.concat(arr3)
            }
            this.obj = arr1;
          } else {
            this.obj = res.data
          }
          if (res.device_version) {
            this.device_version = res.device_version
          }

          var params = {
            deviceSn: this.deviceSn,
            deviceName: this.obj.deviceName
          }
          if (this.device_version && this.device_version.deviceName) {
            params.deviceName = this.device_version.deviceName
          } 
          this.$http
            .refresh(params)
            .then((res) => {
              Notify({ type: "primary", message: res.msg });
          });
        } else {
          var params1 = {
            deviceSn: this.deviceSn,
            deviceName: res.data.deviceName
          }
         
          this.$http
            .refresh(params1)
            .then((res) => {
              Notify({ type: "primary", message: res.msg });
          });
          Notify({ type: "primary", message: res.msg });
        }
      });
    },
    popOutput(num) {
      var params = {
        index: num,
        deviceSn: this.deviceSn
      }
      if (this.device_version) {
        this.showLoading = true
        params.deviceName = this.device_version.deviceName
      } else {
        params.deviceName = this.obj.deviceName
      }
      this.$http
        .popOutput(params)
        .then((res) => {
          console.log(res);
          setTimeout(res3=>{
            this.showLoading = false
          },2000)
          Notify({ type: "primary", message: res.msg });
        });
    },
    popShutOutput(num) {
      var params = {
        index: num,
        deviceSn: this.deviceSn
      }
      if (this.device_version) {
        this.showLoading = true
        params.deviceName = this.device_version.deviceName
      } else {
        params.deviceName = this.obj.deviceName
      }
      this.$http
        .popShutOutput(params)
        .then((res) => {
          console.log(res);
          setTimeout(res3=>{
            this.showLoading = false
          },2000)
          Notify({ type: "primary", message: res.msg });
          
        });
    },
    getPbStatus(item) {
      if (this.deviceSn.startsWith('HQTF')) {
        if (item.sstatus == 0) {
          return '空仓未锁'
        } else if (item.sstatus == 1) {
          return '在仓锁定'
        } else if (item.sstatus == 2) {
          return '在仓未锁'
        } else if (item.sstatus == 3) {
          return '空仓锁定'
        } else {
          return ''
        }

      } else {
        if (item.status == 0) {
          return '空仓'
        } else if (item.status == 1) {
          return '正常'
        } else if (item.status == 3) {
          return '未锁'
        } else if (item.status == 4) {
          return '故障'
        } else {
          return ''
        }
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.SBei {
  display: flex;
  flex-direction: column;
  align-items: center;

  .zc {
    color: #389e0d;
  }

  .kc {
    color: #fadb14;
  }

  .ws {
    color: #faad14;
  }

  .gz {
    color: #e02020;
  }

  .jiebaang {
    width: 344px;
    height: 28px;
    background: #0070fb;
    border-radius: 4px;
    text-align: center;
    line-height: 28px;
    color: #ffffff;
    margin-bottom: 20px;
  }

  .bk {
    border: 1px solid #0070fb;
    box-sizing: border-box;
    padding: 12px;
  }



  .sb_top {
    width: 344px;
    height: 156px;
    background: #ffffff;
    border-radius: 4px;
    /* border: 1px solid #0070FB; */
    font-size: 14px;
    color: #333333;
    margin-bottom: 12px;


    .top_h {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 25%;

      .ccid {
        width: 70%;
        /* overflow: auto; */
        display: block;
        word-break: break-all;
        word-wrap: break-word;
      }

      .sqjg {
        padding: 2px 12px;
        height: 28px;
        background: #0070fb;
        border-radius: 4px;
        text-align: center;
        line-height: 28px;
        color: #ffffff;
      }
    }
  }

  .sb_top_f {
    width: 344px;
    background: #ffffff;
    border-radius: 4px;
    /* border: 1px solid #0070FB; */
    font-size: 13.5px;
    line-height: 25px;
    color: #333333;
    margin-bottom: 12px;


    .top_h {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ccid {
        width: 70%;
        /* overflow: auto; */
        display: block;
        word-break: break-all;
        word-wrap: break-word;
      }

      .sqjg {
        padding: 2px 12px;
        background: #0070fb;
        border-radius: 4px;
        text-align: center;
        line-height: 24px;
        color: #ffffff;
      }
    }


  }

  .sb_top_fbox {
    width: 350px;
    background: #ffffff;
    border-radius: 4px;
    font-size: 14px;
    color: #333333;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .sb_top_fh {
      width: 100%;
      padding: 8px 8px;
      box-sizing: border-box;
      background-color: rgba(0, 112, 251, 0.2);
      margin-bottom: 10px;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .sb_item {
      width: 344px;
      border-radius: 4px;
      border: 1px solid #0070fb;
      box-sizing: border-box;
      padding: 10px;
      margin-bottom: 10px;

      .top_h {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 20px;

        .ccid {
          width: 70%;
          /* overflow: auto; */
          display: block;
          word-break: break-all;
          word-wrap: break-word;
        }

        .sqjg {
          padding: 2px 10px;
          box-sizing: border-box;
          background: #0070fb;
          border-radius: 4px;
          text-align: center;
          color: #ffffff;
        }
      }


    }


  }
}
.parentBox {
  height: 100%;
  background: rgb(31, 31, 31, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .loadBox .loaderContantBox {
    color: white;
    font-size: 40px;
    overflow: hidden;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transform: translateZ(0);
    /* animation：规定完成动画所花费的时间，该属性必须规定，否则动画时长为0，无法播放 */
    animation: loadBox 1.7s infinite ease, round 1.7s infinite ease;
  }
 
  @keyframes loadBox {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
 
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
 
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
        -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
        -0.297em -0.775em 0 -0.477em;
    }
 
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
        -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
        -0.749em -0.34em 0 -0.477em;
    }
 
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
        -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
        -0.82em -0.09em 0 -0.477em;
    }
 
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
 
  @keyframes round {
    0% {
      transform: rotate(0deg); /* 开始旋转 div 元素 */
    }
 
    100% {
      transform: rotate(360deg); /* 结束旋转 div 元素 */
    }
  }
}
</style>